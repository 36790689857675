import React from 'react';
// Images
import CriticalIcon from '../../images/critical-icon-red.svg';
import WarningIcon from '../../images/warning-icon-orange.svg';
import InfoIcon from '../../images/info-icon-grey.svg';
import SuccessIcon from '../../images/checkmark-outline-icon-green.svg';
// Style
import S from './style';

interface ContextualAlertProps {
  text: string;
  type?: 'error' | 'warning' | 'success' | 'info';
}

const ContextualAlert = ({text, type = 'error'}: ContextualAlertProps) => {
  const getAlertIcon = () => {
    switch (type) {
      case 'warning':
        return WarningIcon;
      case 'success':
        return SuccessIcon;
      case 'info':
        return InfoIcon;
      default:
        return CriticalIcon;
    }
  };

  return (
    <S.AlertWrapper>
      <div>
        <S.AlertIcon src={getAlertIcon()} />
      </div>
      <S.AlertText $type={type}>{text}</S.AlertText>
    </S.AlertWrapper>
  );
};

export default React.memo(ContextualAlert);
