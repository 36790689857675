import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-new';

const S: Record<string, React.ElementType> = {};

S.AlertWrapper = styled.div`
  padding-top: ${props => props.theme.spacing.XS};
  display: flex;
`;

S.AlertIcon = styled.img`
  width: 16px;
  margin-right: ${props => props.theme.spacing.XS};
  padding-top: 1px;
`;

S.AlertText = styled(Ag.ParagraphSM)(
  (props: {theme: DefaultTheme; $type: string}) => `
  && {
    color: ${
      (props.$type === 'error' && props.theme.contentColor.contentNegative) ||
      (props.$type === 'warning' && props.theme.contentColor.contentWarning) ||
      (props.$type === 'success' && props.theme.contentColor.contentPositive) ||
      props.theme.contentColor.contentSubdued
    };
  }
`
);

export default S;
