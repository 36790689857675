import {ReactElement, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NextPage} from 'next';
import {useRouter} from 'next/router';
// import fetch from 'node-fetch';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Api from '../library/api';
import {destroyCookie} from 'nookies';
// Components
import Modal from '@brightlive/shared/components/Modal';
import LoginContent from '@brightlive/shared/components/login/LoginContent';
import ForgotPasswordEmailContent from '@brightlive/shared/components/login/ForgotPasswordEmailContent';
import ForgotPasswordSentContent from '@brightlive/shared/components/login/ForgotPasswordSentContent';
import SecurityContentCode from '@brightlive/shared/components/login/SecurityContentCode';
import {withLayout} from 'components/shared/Layout';
// Redux
import {setAuthToken, updateCurrentUser} from '../redux/auth/actions';
// Helpers
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
// Styles
import S from 'styles/Login/style';
import {AuthReducer} from 'redux/auth/reducer';
import {getAuthErrorMessage} from '@brightlive/shared/helpers/auth';

declare global {
  interface Window {
    recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  }
}

const Login: NextPage = (): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [codeError, setCodeError] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [resetPasswordValue, setResetPasswordValue] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');
  const [page, setPage] = useState('login');
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('email');
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult | null>(null);
  const authToken = useSelector(
    (state: AuthReducer) => state.auth.auth.authToken || ''
  );
  useEffect(() => {
    // Page Type Name
    window?.dataLayer?.push({pageType: 'cs_onboarding'});
  }, []);
  const verifyToken = async idToken => {
    const LOGIN_URL = process.env.NEXT_PUBLIC_CF_LOGIN_URL;
    const payload = {
      idToken,
    };

    const isAuthenticated = await axios({
      method: 'post',
      url: LOGIN_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      data: JSON.stringify(payload),
      withCredentials: true,
    })
      .then(res => {
        return res.data;
      }) // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(err => {
        return 'unauthorized';
      });
    return isAuthenticated;
  };

  // Handle Email Login
  const handleEmailLogin = async () => {
    setLoading(true);
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(emailValue, passwordValue);
      const idToken = await result?.user?.getIdToken();
      const response = await verifyToken(idToken);

      if (response === 'authorized') {
        dispatch(setAuthToken(idToken));
        const userData = await Api.fetchCurrentUser(idToken);
        if (
          userData?.roles?.includes('creator') ||
          userData?.roles?.includes('admin')
        ) {
          // Set current user
          await dispatch(updateCurrentUser(userData));
          setLoading(false);
          router.push('/home');
        } else {
          setEmailError('Unauthorized');
          destroyCookie(null, '__session');
          destroyCookie(null, 'cssc');
          setLoading(false);
          router.push('/');
        }
      }
    } catch (error: unknown) {
      const {code, message} = error as firebase.auth.Error;
      setEmailError(getAuthErrorMessage(code, message) ?? '');
      setLoading(false);
    }
  };

  // Handle Phone Login
  const handlePhoneLogin = async redirectPage => {
    setLoading(true);
    // Set recaptcha verifier
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'grecaptcha-button',
      {
        size: 'invisible',
      }
    );
    try {
      // Check if Phone is unique
      const response = await Api.get(
        `/users/unique-phone?phone=${phoneValue}`
      ).execute(authToken);
      // if phone doesn't exist in the system, show signup message
      if (response.isUnique) {
        setPhoneError(
          'There is no account associated with this phone number. Sign up to continue.'
        );
        setLoading(false);
        return;
      }
      // Continue sign in if phone exists
      const formatted = `+1${phoneValue}`;
      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(formatted, recaptchaVerifier);
      // SMS sent. Set confirmation result to check against in code verification
      setConfirmationResult(confirmationResult);
      if (redirectPage) {
        // redirect to security page
        setPage(redirectPage);
      }
      setLoading(false);
      return;
    } catch (error: unknown) {
      const {code, message} = error as firebase.auth.Error;
      setPhoneError(getAuthErrorMessage(code, message));
      // reset the reCAPTCHA so the user can try signing in again
      const widgetId = await recaptchaVerifier.render();
      grecaptcha.reset(widgetId);
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (
      page === 'reset' ||
      page === 'security-code-login' ||
      page === 'reset-sent'
    ) {
      setPage('login');
    }
    return null;
  };

  const handleResetPassword = async () => {
    setLoading(true);
    // Check for validation errors
    if (!validateEmail(resetPasswordValue)) {
      setResetPasswordError('Please enter a valid email');
      setLoading(false);
      return;
    }
    try {
      const value = resetPasswordValue;
      // Dispatch
      await Api.post('/users/reset-password', {type: 'email', value}).execute(
        authToken
      );
      // Set page to the second
      setPage('reset-sent');
      setLoading(false);
    } catch (error: unknown) {
      const {message} = error as Error;
      setResetPasswordError(message);
      setLoading(false);
      return;
    }
  };

  // Confirm Phone Login Code
  const confirmPhoneCode = async codeValue => {
    try {
      if (confirmationResult) {
        const result = await confirmationResult.confirm(codeValue);
        const idToken = await result?.user?.getIdToken();
        const response = await verifyToken(idToken);
        if (response === 'authorized') {
          // Fetch user data with token
          const fetchedUser = await Api.fetchCurrentUser(idToken);
          // Set current user
          await dispatch(updateCurrentUser(fetchedUser));
          router.push('/home');
        } else {
          setEmailError('Unauthorized');
        }
      } else {
        return setCodeError('Code has expired, try a new one below');
      }
    } catch (error: unknown) {
      const {message} = error as Error;
      setCodeError(message);
    }
  };

  const getContent = () => {
    if (page === 'reset') {
      return (
        <ForgotPasswordEmailContent
          loading={loading}
          handleResetPassword={handleResetPassword}
          resetPasswordError={resetPasswordError}
          resetPasswordValue={resetPasswordValue}
          setResetPasswordValue={setResetPasswordValue}
          setResetPasswordError={setResetPasswordError}
        />
      );
    } else if (page === 'reset-sent') {
      return (
        <ForgotPasswordSentContent
          resetPasswordValue={resetPasswordValue}
          handleResetPassword={handleResetPassword}
        />
      );
    } else if (page === 'security-code-login') {
      return (
        <SecurityContentCode
          phoneValue={phoneValue}
          codeError={codeError}
          codeValue={codeValue}
          setCodeError={setCodeError}
          setCodeValue={setCodeValue}
          confirmPhoneCode={confirmPhoneCode}
          handlePhoneLogin={handlePhoneLogin}
        />
      );
    } else {
      return (
        <LoginContent
          loading={loading}
          setPage={setPage}
          setSelected={setSelected}
          selected={selected}
          phoneValue={phoneValue}
          setPhoneValue={setPhoneValue}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          handleEmailLogin={handleEmailLogin}
          handlePhoneLogin={handlePhoneLogin}
          isCreator={true}
        />
      );
    }
  };

  const backIsVisible = ['reset', 'security-code-login', 'reset-sent'].includes(
    page
  );

  return (
    <S.Page>
      <Modal
        mobileHeight={'100%'}
        backVisible={backIsVisible}
        handleBack={handleBack}
      >
        {getContent()}
      </Modal>
      <div id="grecaptcha-button" />
    </S.Page>
  );
};

Login.getInitialProps = async ({query, res}) => {
  // Grab reset password queries if applicable
  const mode = query?.mode;
  const email = query?.email;
  const oobCode = query?.oobCode;
  if (mode && oobCode && res) {
    if (mode === 'resetPassword' || mode === 'creatorRegistration') {
      res.writeHead(301, {
        Location: `/reset-password?email=${email}&mode=${mode}&code=${oobCode}`,
      });
      res.end();
    }
  }
  return {mode, oobCode};
};
export default withLayout(Login, true);
