import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints';

const S: Record<string, React.ElementType> = {};

S.Page = styled.div`
  background: ${props => props.theme.color.black};

  ${media.tablet} {
    height: 100vh;
    width: 100vw;
  }
`;

export default S;
