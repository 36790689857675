import React from 'react';
// Components
import TextInput from '@brightlive/shared/components/TextInput';
// Styles
import S from '../style';

const SecurityContent = ({
  setCodeValue,
  codeValue,
  setCodeError,
  phoneValue,
  confirmPhoneCode,
  handlePhoneLogin,
  codeError,
}) => {
  const getPhoneNumber = () => {
    return '******' + phoneValue.substr(phoneValue.length - 4);
  };

  const handleCodeChange = e => {
    setCodeValue(e.target.value);
    setCodeError('');
    // TODO : add validation
    if (codeValue.length === 6 || e.target.value.length === 6) {
      //else its for login
      confirmPhoneCode(e.target.value);
    }
  };

  return (
    <S.LoginWrapper>
      <S.LoginContent>
        <S.Title>Enter verification code</S.Title>
        <S.SubtitleLeft>{`A one-time code was sent to ${getPhoneNumber()}. Enter the code below to verify your account.`}</S.SubtitleLeft>
        <S.CodeWrapper>
          <S.InputWrapper>
            <TextInput
              label="Six-digit code"
              placeholder=""
              onChange={handleCodeChange}
              value={codeValue}
              error={codeError}
            />
          </S.InputWrapper>
        </S.CodeWrapper>
        <S.AlternateText>
          <S.Link onClick={() => handlePhoneLogin(false)}>Resend Code</S.Link>
        </S.AlternateText>
        <S.BottomContent>
          <S.AlternateText>
            Problems receiving the code?{' '}
            <S.AnchorLink
              href="https://brightlive.zendesk.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact support
            </S.AnchorLink>
          </S.AlternateText>
        </S.BottomContent>
      </S.LoginContent>
    </S.LoginWrapper>
  );
};

export default React.memo(SecurityContent);
